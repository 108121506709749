.pageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
}

.innerPageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.innerPageWrapperCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dontDoThisImage {
    width: 450px;
}

.sorryMessage {
    font-size: 24px;
    font-weight: 600;
}

.textStrong {
    font-weight: 600;
}

.selectOption {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.selectOptionLabel {
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 16px;
    margin-left: 16px;
}

.cancelReasonLabel {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
}

.additionalCancelReasonLabel {
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 16px;
}

.cancellationConfirmationAction {
    color: #000000;
}

.selectOptions {
    margin-left: 30px;
}

.textarea {
    width: 90%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}

.buttonGroup {
    margin-top: 20px;
}

.extraMessage {
    font-size: 14px;
    color: #4f5855;
    font-style: italic;
    font-weight: 500;
    margin-top: 10px;
}

.cancelledContainer {
    margin: 0 auto;
    text-align: center;
    padding: 20px;
}

.cancelledTitle {
    color: #333;
    font-size: 45px;
}

.cancelledMessage {
    color: #666;
    font-size: 22px;
}

.cancelledMessageLogout {
    color: #666;
    font-size: 20px;
    margin-top: 20px;
}