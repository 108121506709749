.hide__show__wrapper__v2{
    display: flex;
    align-items: center;
    grid-gap: 16px;
    margin:  0 0 16px 14px;
}

.BillingModule-container-parent-class .user__alert___usage_info_header{
    background-color: #cc3300d0;
    padding: 0px 15px;;
}

.BillingModule-container-parent-class .user__alert___usage_info_header .user___alert___icon{
    color: white;
    margin: 0px 10px;
}

.BillingModule-container-parent-class .user__alert___usage_info_header .user___alert__text{
    color: white;
    font-size: 17px;
    margin: 0px 0px 0px 15px;
}

.BillingModule-container-parent-class .red--bg--color {
    background: #FF264A !important;
}

.BillingModule-container-parent-class .red--bg--text--color {
    background: #FF264A !important;
    color: #FFFFFF !important;
}

.trial-box{
    background-color: #EDF5FE;
    border: 1px solid blue;
    padding: 10px;
}

.upgrade-btn
{
    text-align: end;
}

.expireDate
{
    float: right;
}