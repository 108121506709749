.logo_img {
  height: 50px;
  width: 50px;
}
.logo-text {
  font-size: 26px;
}
.invoice__table {
  margin-top: 50px;
  margin-bottom: 120px;
}
.invoice__table tr {
  border: 0;
}
.invoice__table thead tr {
  background: var(--gray_dark);
}
.invoice__table thead tr td:not(:last-child) {
  border-right: 2px solid var(--white);
}
.invoice__table thead tr td:first-child {
  border-radius: 5px 0 0 5px;
}
.invoice__table thead tr td:last-child {
  border-radius: 0 5px 5px 0;
}
.subtotal {
  border-bottom: 1px solid var(--gray_dark);
}
.download__pdf__btn {
  font-size: 15px;
  max-width: 186px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
}

@media(max-width:992px){
  .margin-tab{
    margin-bottom: 30px;
  }
}