.invoice-details .logo_img {
	 height: 50px;
	 width: 50px;
}
 .invoice-details .logo-text {
	 font-size: 26px;
}
 .invoice-details .invoice__table {
	 margin-top: 50px;
	 margin-bottom: 120px;
}
 .invoice-details .invoice__table tr {
	 border: 0;
}
 .invoice-details .invoice__table thead tr {
	 background: var(--gray_dark);
}
 .invoice-details .invoice__table thead tr td:not(:last-child) {
	 border-right: 2px solid var(--white);
}
 .invoice-details .invoice__table thead tr td:first-child {
	 border-radius: 5px 0 0 5px;
}
 .invoice-details .invoice__table thead tr td:last-child {
	 border-radius: 0 5px 5px 0;
}
 .invoice-details .subtotal {
	 border-bottom: 1px solid var(--gray_dark);
}
 .invoice-details .download__pdf__btn {
	 font-size: 15px;
	 max-width: 186px;
	 width: 100%;
	 height: 50px;
	 border-radius: 5px;
}
 @media (max-width: 992px) {
	 .invoice-details .margin-tab {
		 margin-bottom: 30px;
	}
}

.billing-wrapper #vertical-tabpanel-1{
	width: 50%;
}
.scrollable_table__height__calc .scrollable_table__close.scrollable_table {
	max-height: calc(100vh - 190px) !important
}
.scrollable_table__height__calc .scrollable_table__open.scrollable_table {
	max-height: calc(100vh - 295px) !important
}


@media(min-width: 2500px) {
    /* .scrollable_table__height__calc .scrollable_table__close.scrollable_table {
        max-height: calc(100vh - 190px) !important
    } */
	.scrollable_table__height__calc .scrollable_table__open.scrollable_table {
        max-height: calc(100vh - 428px) !important
    }
}
 

.usage_history_top, .invoice_history_top {
	border-bottom: 1px solid #c1c1c180;
}

body .usage_history_table, body .invoice_history_table {
    border-spacing: 0 10px;
}
body .invoice_history_table thead,
body .usage_history_table thead {
    background: #71758e14;
}