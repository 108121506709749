.custom__alert__ui__container__pypepro{
    background: var(--gray_dark);
    max-width: 450px;
    width: 100%;
    min-height: 365px;
    border-radius: 14px;
    position: relative;
    box-shadow: 0px 10px 7px #ddd;
}
.custom__alert__ui__container__pypepro .custom__alert_main_body{
    padding: 20px;
}
.custom__alert__ui__container__pypepro .custom__alert__icon{
    width: 70px;
    height: 70px;
    background: var(--gray_dark);
    border: 7px solid #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 42%;
    top: -32px;
}
.custom__alert__ui__container__pypepro .custom__alert_main_body{
    text-align: center;
    padding-top: 24px;
}
.custom__alert__ui__container__pypepro .custom__alert_main_body .custom__alert__head__title{
    font-size: 24px;
    color: var(--red);
    padding-top: 20px;
}
.custom__alert__ui__container__pypepro .custom__alert_main_body .custom__alert__description{
    padding: 20px 0;
    font-size: 15px;
}
.custom__alert__ui__container__pypepro .custom__alert_main_body .custom_alert_type_word{
    font-weight: 500;
    color: var(--red);
}
.custom__alert__ui__container__pypepro .custom__alert_main_body  .custom_alert_confirmation_input{
    border: 1px solid #ddd;
    background: #ffff;
    border-radius: 6px;
    padding: 0 10px;
    margin: 12px 0 10px 0;
}
.custom__alert__ui__container__pypepro .custom__alert__footer__container{
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0;
}
.custom__alert__ui__container__pypepro .custom__alert__footer__container  .custom__alert__footer__confirm{
    flex: 1;
    background: var(--blue-1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    color: #fff;
    cursor: pointer;
    border-bottom-right-radius: 14px
}
.custom__alert__ui__container__pypepro .custom__alert__footer__container .custom__alert__footer__cancel{
    flex: 1;
    background: var(--red);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    color: #fff;
    cursor: pointer;
    border-bottom-left-radius: 14px
}
.custom__alert__ui__container__pypepro .custom__alert_main_body #custom_alert_confirmation_input_error_id{
    display: none;
    font-weight: 400;
    color: var(--red);
    font-size: 12x;
    text-align: left;
}
.custom__alert__ui__container__pypepro .custom__alert_main_body .show_custom_alert_confirmation_input_error{
    display: block !important;
}